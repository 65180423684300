import { useCallback } from 'react';
import { HourInMilliseconds, Routes } from '@/constants';
import { recommendationWidgetConfig } from '@/constants/recommendations';
import { useQuery } from '@tanstack/react-query';

import {
  RecommendedItem,
  UseRecommendationItemsProps,
} from '@/types/Recommendation';

import {
  Brand,
  GetRecommendationsQuery,
  RecommendationFilter,
  RecommendationUseCase,
} from '@/lib/gql/graphql';
import { normalizeUrl } from '@/utils/normalizeUrl';

export const useRecommendationItems = ({
  brand,
  itemId,
  recommendationType,
  size,
}: UseRecommendationItemsProps) => {
  const fetchRecommendations = useCallback(async () => {
    const { types: recommendationTypes } =
      recommendationWidgetConfig[brand] ||
      recommendationWidgetConfig[Brand.Motortrend]!;
    const filter: RecommendationFilter = {
      type: recommendationTypes.includes(recommendationType)
        ? recommendationType
        : recommendationTypes[0],
    };

    const genres = recommendationWidgetConfig[brand]?.genres;
    if (genres && genres.length > 0) {
      filter.genres = genres;
    }

    const _data = await fetch('/nwapi/recommendations/', {
      body: JSON.stringify({
        filter,
        itemId,
        size,
        useCase: RecommendationUseCase.SimilarItems,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (!_data.ok) {
      return [];
    }

    const items =
      (await _data.json()) as GetRecommendationsQuery['recommendations'];

    const normalizedItems: RecommendedItem[] =
      items?.contents
        .map((content) => {
          if (!content) {
            return null;
          }

          switch (content.__typename) {
            case 'Article':
              return {
                id: content.articleId ?? '',
                link:
                  normalizeUrl({ paths: [content.seo?.canonicalPath] }) ?? '',
                title: content.hed ?? '',
                thumbnailUrl: content.teaseImage?.url ?? '',
                type: 'article',
              };
            case 'Video':
              return {
                id: content.videoId ?? '',
                link: normalizeUrl({
                  paths: [Routes.Watch, content.videoId, content.slug],
                }),
                thumbnailUrl: content.thumbnailUrl ?? '',
                title: content.title ?? '',
                type: 'video',
              };
            case 'Vehicle':
              return {
                id: content.makeModelArticle?.id ?? '',
                link:
                  normalizeUrl({
                    paths: [
                      Routes.BuyersGuideHomepage,
                      content.make?.slug?.toLowerCase() ?? '',
                      content.model?.slug?.toLowerCase() ?? '',
                      content.year,
                    ],
                  }) ?? '',
                subTitle: content.msrpRange,
                thumbnailUrl: content.thumbnailUrl ?? '',
                title: `${content.year} ${content.make?.name} ${content.model?.name}`,
                type: 'vehicle',
              };
            default:
              return null;
          }
        })
        .filter((item): item is RecommendedItem => item !== null) ?? [];
    return normalizedItems;
  }, [brand, itemId, recommendationType, size]);

  const { data: recommendationItems, isFetching } = useQuery({
    queryKey: ['recommendations', brand, itemId, recommendationType],
    queryFn: fetchRecommendations,
    staleTime: (query) => {
      return query.state.data?.length ? HourInMilliseconds : 0;
    },
  });

  return {
    recommendationItems: recommendationItems ?? [],
    isFetching,
  };
};
