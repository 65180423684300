import { type AdSizes } from '@/types/AdSizes';
import { type AdTargeting } from '@/types/AdTargeting';

export class BuildRightRailLayout {
  private ntvTargeting = {
    native: '300x250',
    ntvPlacement: '1115836',
  };

  private sponsoredRailTargeting: AdTargeting[][] = [
    [
      {
        ...this.ntvTargeting,
        pos: ['top', '1'],
      },
    ],
    [
      {
        ...this.ntvTargeting,
        pos: ['middle', '2'],
      },
    ],
    [
      {
        ...this.ntvTargeting,
        pos: ['bottom', '3'],
      },
    ],
  ];

  private certfiedRailTargeting: AdTargeting[][] = [
    [
      {
        ...this.ntvTargeting,
        pos: ['top', '1'],
      },
    ],
    [
      {
        ...this.ntvTargeting,
        pos: ['bottom', '2'],
      },
    ],
  ];

  private sponsoredRailSize: AdSizes = {
    desktop: [
      [300, 250],
      [300, 600],
    ],
    laptop: [
      [300, 250],
      [300, 600],
    ],
    mobile: [],
    tablet: [
      [300, 250],
      [300, 600],
    ],
  };

  private defaultRailTargeting: AdTargeting[][] = [
    [
      {
        pos: ['top'],
      },
      { pos: ['bottom'] },
    ],
  ];

  private ymmRailTargeting: AdTargeting[][] = [
    [
      {
        pos: ['top'],
      },
      { pos: ['middle'] },
    ],
    [{ pos: ['bottom'] }],
    [{ pos: ['carmatch'], sponsored: 'true' }],
    [{ pos: ['newsletter'], sponsored: 'true' }],
  ];

  private defaultRailSizes: AdSizes[] = [
    {
      desktop: [[300, 250]],
      laptop: [[300, 250]],
      mobile: [],
      tablet: [[300, 250]],
    },
    {
      desktop: [
        [300, 250],
        [300, 600],
      ],
      laptop: [
        [300, 250],
        [300, 600],
      ],
      mobile: [],
      tablet: [
        [300, 250],
        [300, 600],
      ],
    },
  ];

  private extraLargeScreenRailSize: AdSizes[] = [
    {
      desktop: [[300, 250]],
      laptop: [[300, 250]],
      mobile: [],
      tablet: [[300, 250]],
    },
    {
      desktop: [[300, 250]],
      laptop: [[300, 250]],
      mobile: [],
      tablet: [[300, 250]],
    },
  ];

  public buildSponsoredAdLayout = (
    numBlocks: number,
  ): { adSizes: AdSizes[]; adTargeting: AdTargeting[] }[] => {
    return Array.from({ length: numBlocks }, (_, i) => {
      const blockIndex = i % this.sponsoredRailTargeting.length;

      return {
        adSizes: [this.sponsoredRailSize],
        adTargeting: this.sponsoredRailTargeting[blockIndex],
      };
    });
  };

  public buildCertifiedAdLayout = (
    numBlocks: number,
  ): { adSizes: AdSizes[]; adTargeting: AdTargeting[] }[] => {
    return Array.from({ length: numBlocks }, (_, i) => {
      const blockIndex = i % this.certfiedRailTargeting.length;

      return {
        adSizes: [this.sponsoredRailSize],
        adTargeting: this.certfiedRailTargeting[blockIndex],
      };
    });
  };

  public build2xlScreenAdLayout = (
    numBlocks: number,
  ): { adSizes: AdSizes[]; adTargeting: AdTargeting[] }[] => {
    let adCounter = 0;
    return Array.from({ length: numBlocks }, (_, i) => {
      // Cycle through the available ad data
      const blockIndex = i % this.defaultRailTargeting.length;

      const blockTargeting = this.defaultRailTargeting[blockIndex].map(
        (targeting) => {
          return {
            ...this.ntvTargeting,
            pos: [`${targeting.pos}`, `${++adCounter}`],
          };
        },
      );

      return {
        adSizes: this.extraLargeScreenRailSize,
        adTargeting: blockTargeting,
      };
    });
  };

  public buildYMMAdLayout = (
    numBlocks: number,
  ): { adSizes: AdSizes[]; adTargeting: AdTargeting[] }[] => {
    let adCounter = 0;
    return Array.from({ length: numBlocks }, (_, i) => {
      // Cycle through the available ad data
      const blockIndex = i % this.ymmRailTargeting.length;

      const blockTargeting = this.ymmRailTargeting[blockIndex].map(
        (targeting) => {
          return {
            ...this.ntvTargeting,
            pos:
              targeting?.sponsored === 'true'
                ? [`${targeting.pos}`]
                : [`${targeting.pos}`, `${++adCounter}`],
            sect: 'buyersguide',
          };
        },
      );

      return {
        adSizes:
          i < 1
            ? [this.sponsoredRailSize, this.sponsoredRailSize]
            : [this.sponsoredRailSize],
        adTargeting: blockTargeting,
      };
    });
  };

  public buildStandardAdLayout = (
    numBlocks: number,
  ): { adSizes: AdSizes[]; adTargeting: AdTargeting[] }[] => {
    let adCounter = 0;
    return Array.from({ length: numBlocks }, (_, i) => {
      // Cycle through the available ad data
      const blockIndex = i % this.defaultRailTargeting.length;

      const blockTargeting = this.defaultRailTargeting[blockIndex].map(
        (targeting) => {
          return {
            ...this.ntvTargeting,
            pos: [`${targeting.pos}`, `${++adCounter}`],
          };
        },
      );

      return {
        adSizes: this.defaultRailSizes,
        adTargeting: blockTargeting,
      };
    });
  };
}
