'use client';

import { useEffect, useState, type FC } from 'react';
import { usePathname } from 'next/navigation';
import cx from 'clsx';

import { type AdSize, type AdSizes } from '@/types/AdSizes';

import AdContainer from '@/components/AdContainer/AdContainer';
import { usePhotoGalleryContext } from '@/components/PhotoCollection/PhotoGalleryContext';
import StickyAd from '@/components/StickyAd';

import { type ArticleTopAdProps } from './ArticleTopAd.props';

const inmarketMobileAdSizes: AdSizes = {
  desktop: [],
  laptop: [],
  mobile: [[320, 50]],
  tablet: [[320, 50]],
};

const inmarketDesktopAdSizes: AdSizes = {
  desktop: [
    [970, 90],
    [728, 90],
  ],
  laptop: [
    [970, 90],
    [728, 90],
  ],
  mobile: [],
  tablet: [],
};

const afterMarketMobileAdSizes: AdSizes = {
  desktop: [],
  laptop: [],
  mobile: [[300, 250]],
  tablet: [[300, 250]],
};

const afterMarketDesktopAdSizes: AdSizes = {
  desktop: [
    [970, 250],
    [970, 90],
    [728, 90],
  ],
  laptop: [
    [970, 250],
    [970, 90],
    [728, 90],
  ],
  mobile: [],
  tablet: [],
};

const adSizes: Record<string, AdSize[]> = {
  afterMarketDesktop: [
    [970, 250],
    [970, 90],
    [728, 90],
  ],
  afterMarketMobile: [[320, 250]],
  inmarketDesktop: [
    [970, 90],
    [728, 90],
  ],
  inmarketMobile: [[320, 50]],
};

const sizeMapping: Record<string, googletag.SizeMappingArray> = {
  afterMarketDesktop: [
    [
      [1024, 0],
      [
        [970, 250],
        [970, 90],
        [728, 90],
      ],
    ], // Desktop and Laptop
    [
      [768, 0],
      [
        [970, 250],
        [970, 90],
        [728, 90],
      ],
    ], // Tablet
    [[0, 0], []], // Tablet and Mobile (no ads)
  ],
  afterMarketMobile: [
    [[1024, 0], []], // Desktop (no ads)
    [
      [768, 0],
      [300, 250],
    ], // Tablet
    [
      [0, 0],
      [300, 250],
    ], // Mobile
  ],
  inmarketDesktop: [
    [
      [1024, 0],
      [
        [970, 90],
        [728, 90],
      ],
    ], // Desktop and Laptop
    [
      [768, 0],
      [
        [970, 90],
        [728, 90],
      ],
    ], // Tablet
    [[0, 0], []], // Tablet and Mobile (no ads)
  ],
  inmarketMobile: [
    [[1024, 0], []], // Desktop (no ads)
    [
      [768, 0],
      [300, 50],
    ], // Tablet
    [
      [0, 0],
      [320, 50],
    ], // Mobile
  ],
};

const ArticleTopAd: FC<ArticleTopAdProps> = ({
  isAfterMarket,
  isGallery,
  isSponsored,
  pageTargeting,
}) => {
  const { currentPhotoIndex, currentTab } = usePhotoGalleryContext();
  const [adKey, setAdKey] = useState(`${currentPhotoIndex}-${currentTab}`);
  const pathname = usePathname();
  const [key, setKey] = useState(0);

  const mobileSizes = isAfterMarket
    ? afterMarketMobileAdSizes
    : inmarketMobileAdSizes;

  const desktopSizes = isAfterMarket
    ? afterMarketDesktopAdSizes
    : inmarketDesktopAdSizes;

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [pathname]);

  useEffect(() => {
    setAdKey(`${currentPhotoIndex}-${currentTab}-${Date.now()}`);
  }, [currentPhotoIndex, currentTab]);

  const adConfig = {
    desktop: {
      className: 'hidden justify-center lg:flex lg:min-h-[90px] lg:min-w-full',
      sizeMapping:
        sizeMapping[isAfterMarket ? 'afterMarketDesktop' : 'inmarketDesktop'],
      sizes: adSizes[isAfterMarket ? 'afterMarketDesktop' : 'inmarketDesktop'],
      targeting: { pos: ['top', '1'] },
    },
    mobile: {
      className: cx('flex justify-center lg:hidden', {
        'min-w-[300px] min-h-[250px]': isAfterMarket,
        'min-w-[320px] min-h-[50px]': !isAfterMarket,
      }),
      sizeMapping:
        sizeMapping[isAfterMarket ? 'afterMarketMobile' : 'inmarketMobile'],
      sizes: adSizes[isAfterMarket ? 'afterMarketMobile' : 'inmarketMobile'],
      targeting: isAfterMarket
        ? { pos: ['mobile_header'] }
        : { pos: ['top', '1'] },
    },
  };

  const topAd = (
    <div
      className="align-center flex justify-center bg-neutral-7 transition-colors dark:bg-neutral-3"
      key={adKey || key}
    >
      <AdContainer
        adContainerProps={{
          className: adConfig.desktop.className,
        }}
        adProps={{
          className: 'flex items-center',
          pageTargeting,
          sizes: desktopSizes,
          targeting: adConfig.desktop.targeting,
        }}
      />
      <AdContainer
        adContainerProps={{
          className: cx('flex lg:hidden', adConfig.mobile.className),
        }}
        adProps={{
          className: 'flex items-center',
          pageTargeting,
          sizes: mobileSizes,
          targeting: adConfig.mobile.targeting,
        }}
      />
    </div>
  );

  const isSticky = !((isSponsored && isGallery) || isAfterMarket);

  return isSticky ? <StickyAd className="z-40">{topAd}</StickyAd> : topAd;
};

export default ArticleTopAd;
