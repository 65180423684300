'use client';

import { useEffect, useState, type FunctionComponent } from 'react';
import {
  defaultRecommendationConfig,
  defaultRecommendationCount,
  desktopVisibleCardCount,
  mobileVisibleCardCount,
  recommendationWidgetConfig,
} from '@/constants/recommendations';
import useStore from '@/store';
import { RecommendationWidgetStrings as strings } from '@/translations';
import {
  Tabs,
  TabsContent,
  TabsItem,
  TabsList,
  Typography,
  useContainerBreakpoint,
} from '@motortrend/ids';
import cx from 'clsx';

import { type RecommendationType } from '@/lib/gql/graphql';
import getArticlePageViewGroup from '@/utils/nitrous/getArticlePageViewGroup';
import { useRecommendationItems } from '@/hooks/useRecommendationItems';

import { brandSelector } from '@/store/selectors/brand';

import RecommendationsGrid from './RecommendationsGrid';
import { type RecommendationWidgetProps } from './RecommendationWidget.props';

const RecommendationWidget: FunctionComponent<RecommendationWidgetProps> = ({
  count = defaultRecommendationCount,
  path,
  uuid,
}) => {
  const { brand } = useStore(brandSelector);

  const { types: recommendationTypes } =
    recommendationWidgetConfig[brand] || defaultRecommendationConfig;
  const { tabs } =
    recommendationWidgetConfig[brand] || defaultRecommendationConfig;
  const showTabs = !!(tabs && tabs?.length > 0);

  const [visibleCardCount, setVisibleCardCount] = useState(0);
  const [currentTab, setCurrentTab] = useState<RecommendationType>(
    recommendationTypes[0],
  );

  const viewGroup = getArticlePageViewGroup(path)
    .toLowerCase()
    .replaceAll(' ', '_');
  const itemId = `motortrend-article-${viewGroup}-${uuid}`;

  const { isFetching, recommendationItems } = useRecommendationItems({
    brand,
    itemId,
    recommendationType: currentTab,
    size: count,
  });

  const { ref: isDesktopRef, result: isDesktop } = useContainerBreakpoint('lg');
  const { ref: isTabletRef, result: isTablet } = useContainerBreakpoint('md');
  const isDesktopOrTablet = !!(isDesktop || isTablet);

  useEffect(() => {
    setVisibleCardCount(
      isDesktopOrTablet ? desktopVisibleCardCount : mobileVisibleCardCount,
    );
  }, [isDesktopOrTablet, currentTab]);

  const handleLoadMore = () => {
    setVisibleCardCount(
      (prev) =>
        prev +
        (isDesktopOrTablet ? desktopVisibleCardCount : mobileVisibleCardCount),
    );
  };

  const handleTabChange = (value: string) => {
    setCurrentTab(value as RecommendationType);
  };

  return (
    <section
      ref={(el) => {
        isDesktopRef(el);
        isTabletRef(el);
      }}
    >
      <Typography
        as="h2"
        className={cx({ 'mb-4': !showTabs })}
        data-testid="heading"
        lg="h4"
        variant="h5"
      >
        {strings.Heading}
      </Typography>
      {showTabs ? (
        <Tabs defaultValue={currentTab} onValueChange={handleTabChange}>
          <TabsList>
            {tabs.map(({ label, type }) => (
              <TabsItem key={type} value={type}>
                {label}
              </TabsItem>
            ))}
          </TabsList>
          <TabsContent value={currentTab}>
            <RecommendationsGrid
              handleLoadMore={handleLoadMore}
              isDesktopOrTablet={isDesktopOrTablet}
              isFetching={isFetching}
              recommendationItems={recommendationItems}
              visibleCardCount={visibleCardCount}
            />
          </TabsContent>
        </Tabs>
      ) : (
        <RecommendationsGrid
          handleLoadMore={handleLoadMore}
          isDesktopOrTablet={isDesktopOrTablet}
          isFetching={isFetching}
          recommendationItems={recommendationItems}
          visibleCardCount={visibleCardCount}
        />
      )}
    </section>
  );
};

export default RecommendationWidget;
