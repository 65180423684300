'use client';

import {
  useCallback,
  useState,
  type ReactElement,
  type ReactNode,
} from 'react';
import useStore from '@/store';
import { withErrorBoundary } from 'react-error-boundary';

import { type AdSizes } from '@/types/AdSizes';

import { Brand } from '@/lib/gql/graphql';
import { NoUiErrorFallback } from '@/components/ErrorFallback';

import { brandSelector } from '@/store/selectors/brand';

import HotrodLogo from '../../node_modules/@motortrend/ids/lib/assets/images/hotrod.svg';
import Ad from '../Ad';
import { type BrandLogoProps } from './BrandLogo.props';

const articleSponsorshipAdSizes: AdSizes = {
  desktop: [[260, 24]],
  laptop: [[260, 24]],
  mobile: [[260, 24]],
  tablet: [[260, 24]],
};

export const BrandLogoConfig: Partial<Record<Brand, ReactElement>> = {
  [Brand.Hotrod]: (
    <HotrodLogo className="w-full max-w-24 text-[#B40810]" title="hotrod" />
  ),
};

const BrandLogo = ({
  pageTargeting,
  primaryVerticalSlug,
}: BrandLogoProps): ReactNode => {
  const [showAd, setShowAd] = useState(true);
  const { brand } = useStore(brandSelector);

  const handleAdRender = useCallback((isEmpty: boolean) => {
    setShowAd(!isEmpty);
  }, []);

  return (
    <div className="mb-4 flex flex-row items-center gap-x-4">
      {primaryVerticalSlug !== brand &&
        BrandLogoConfig[primaryVerticalSlug as Brand]}

      {showAd && (
        <Ad
          className="[&_iframe]:rounded-sm [&_iframe]:outline [&_iframe]:outline-4 [&_iframe]:outline-neutral-8"
          onAdRendered={handleAdRender}
          pageTargeting={pageTargeting}
          sizes={articleSponsorshipAdSizes}
          targeting={{
            pos: ['breadcrumbs'],
          }}
        />
      )}
    </div>
  );
};

export default withErrorBoundary(BrandLogo, {
  FallbackComponent: NoUiErrorFallback,
});
